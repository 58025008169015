<template>
  <!-- <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div> -->

  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <vsud-alert
                    :hidden="hideErrorAlert"
                    :color="errorAlertColor"
                    icon="ni ni-notification-70 ni-md"
                  >
                    <strong>{{ errorAlertType }}</strong> <br />{{
                      errorAlertMessage
                    }}
                  </vsud-alert>
                  <h3 class="font-weight-bolder text-success text-gradient">
                    {{ greetingMessage }}
                  </h3>
                  <p class="mb-0">
                    Enter your vendorId and password to sign in
                  </p>
                </div>
                <div class="card-body">
                  <form
                    role="form"
                    class="text-start"
                    @submit.prevent="processForm"
                  >
                    <label>VendorID</label>
                    <vsud-input
                      id="vendorId"
                      type="tel"
                      placeholder="2348029150812"
                      name="vendorId"
                      v-model:value="vendorId"
                    />
                    <label>Password</label>
                    <vsud-input
                      id="password"
                      type="password"
                      placeholder="Password"
                      name="password"
                      v-model:value="password"
                    />
                    <!-- <vsud-switch id="rememberMe" name="rememberMe" checked>
                      Remember me
                    </vsud-switch> -->
                    <div class="text-center">
                      <vsud-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        full-width
                        >Sign in
                      </vsud-button>
                    </div>
                  </form>
                </div>
                <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link
                      :to="{ name: 'Sign Up' }"
                      class="text-success text-gradient font-weight-bold"
                      >Sign up</router-link
                    >
                  </p>
                </div> -->
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved9.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import VsudInput from "@/components/VsudInput.vue";
import VsudAlert from "@/components/VsudAlert.vue";
// import VsudSwitch from "@/components/VsudSwitch.vue";
import VsudButton from "@/components/VsudButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import { mapStores } from "pinia";
import { loginCall } from "@/libs/index.js";
import { useVendorStore } from "@/store/pinia.js";
import { getGreetingTime } from "@/libs/utils.js";

export default {
  name: "SignIn",
  components: {
    // Navbar,
    AppFooter,
    VsudInput,
    VsudAlert,
    // VsudSwitch,
    VsudButton,
  },
  data() {
    return {
      vendorId: "",
      password: "",
      hideErrorAlert: true,
      errorAlertColor: "dark",
      errorAlertType: "Error!",
      errorAlertMessage: `Either VendorId or Password is invalid; Try again.`,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  computed: {
    ...mapStores(useVendorStore),
    greetingMessage() {
      return getGreetingTime();
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async processForm() {
      this.errorAlertColor = "info";
      this.errorAlertType = "Info!";
      this.errorAlertMessage = "Authentication attempt in progress!";
      this.hideErrorAlert = false;

      let callResp = await loginCall(this.vendorId, this.password);
      if (callResp.ok) {
        this.errorAlertColor = "success";
        this.errorAlertType = "Success!";
        this.errorAlertMessage = "Login successful!";
        this.hideErrorAlert = false;
        setTimeout(() => {
          this.$router.push({ name: "Dashboard" });
        }, 1000);
      } else {
        this.errorAlertColor = "dark";
        this.errorAlertType = "Error!";
        this.errorAlertMessage = `Either VendorId or Password is invalid; Try again.`;
        this.hideErrorAlert = false;
        this.vendorId = "";
        this.password = "";

        setTimeout(() => {
          this.hideErrorAlert = true;
        }, 5000);
      }
    },
  },
};
</script>

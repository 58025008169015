import { defineStore } from 'pinia';

export const useVendorStore = defineStore({
    id: "vendor",
    state: () => ({
        vendorName: "",
        vendorId: "",
        vendorFundInfo: {},
        vendorCommissionInfo: {},
        isAuthenticated: false
    }),
    getters: {
        // getVendorName: (state) => {
        //     return state.vendorName;
        // },
        // getVendorId: (state) => {
        //     return state.vendorId;
        // }
    },
    actions: {
        setVendorName(name) {
            this.vendorName = name;
        },
        setVendorId(id) {
            this.vendorId = id;
        },
        setVendorFundInfo(info) {
            this.vendorFundInfo = info;
        },
        setVendorCommissionInfo(info) {
            this.vendorCommissionInfo = info;
        },
        setAuthTrue() {
            this.isAuthenticated = true;
        },
        setAuthFalse() {
            this.isAuthenticated = false;
        },
        signOut() {
            console.log("Logging user out of dashboard");
            this.vendorId = "";
            this.vendorName = "";
            this.isAuthenticated = false;
        }
    }
});

export const useOrderStore = defineStore({
    id: "order",
    state: () => ({
        airtimeService: "airtime",
        databundleService: "databundle",
        cableTvService: "cabletv",
        electricityService: "electricity",
    })
});
import moment from "moment-timezone";
moment.tz.setDefault("Africa/Lagos");

export const NAIRA_SYMBOL = "₦";

export const momentObj = moment;

export function formatNumber(num) {
    if (typeof num === "string") {
        num = Number(num);
        // console.log(num);
    }
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function formatNumberAsCurrency(num) {
    if (typeof num === "string") {
        num = Number(num);
        // console.log(num);
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function getGreetingTime() {
    let currentTime = moment();
    if (!currentTime || !currentTime.isValid()) {
        return "Hello";
    }

    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening
    const currentHour = parseFloat(currentTime.format("HH"));

    if (currentHour >= splitAfternoon && currentHour < splitEvening) {
        // Between 12 PM and 5PM
        return "Good Afternoon";
    } else if (currentHour >= splitEvening) {
        // Between 5PM and Midnight
        return "Good Evening";
    }
    // Between dawn and noon
    return "Top of the Morning";
}

export function sanitizePhoneNumber(phoneNo, code = "234") {
    let phone = String(phoneNo);
    const firstChar = phone.charAt(0);
    if (firstChar === "0" || firstChar === "+") {
        phone = phone.substring(1);
    }
    if (phone.substring(0, 3) === code) {
        return phone;
    }
    return code + phone;
}


<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Wallet Balance"
          :value="walletBalance"
          :percentage="{
            value: '+505%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-money-coins',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>

      <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          :title="totalSalesTitle"
          :value="totalSalesCount"
          :percentage="{
            value: '+3%',
            color: 'text-success',
          }"
          :icon="{
            component: ' ni ni-world',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>

      <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          :title="totalAmountTitle"
          :value="totalSalesAmount"
          :percentage="{
            value: '+3%',
            color: 'text-success',
          }"
          :icon="{
            component: ' ni ni-cart',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>

      <div class="col-xl-4 col-sm-6 mb-xl-0">
        <mini-statistics-card
          :title="totalCommissionTitle"
          :value="totalSalesCommission"
          :percentage="{
            value: '+5%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-trophy',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7 mb-lg-0 mb-4">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex flex-column h-100">
                  <h5 class="font-weight-bolder">Your Commissions:</h5>
                  <div v-for="item in commissionDetails" :key="item.name">
                    <h5>{{ item.name }}</h5>
                    <ul>
                      <li
                        v-for="moreItem in item.details"
                        :key="moreItem.product"
                      >
                        {{ moreItem.product }}: {{ moreItem.percent }}%
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5">
        <div class="card h-100 p-3">
          <div
            class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
            style="
              background-image: url('https://demos.creative-tim.com/soft-ui-dashboard/assets/img/ivancik.jpg');
            "
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column ../../assets/img/ih-100">
                <h5 class="text-white font-weight-bolder pt-2">
                  You can fund your wallet using the below details:
                </h5>
                <p class="text-white mb-5">
                  Account Number:
                  <span class="font-weight-bold">{{ accountNumber }}</span
                  ><br />Account Name:
                  <span class="font-weight-bold">{{ accountName }}</span>
                  <br />Account's Bank Name:
                  <span class="font-weight-bold">{{ accountBankName }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import { mapStores } from "pinia";
import { getWalletBalance, getTotalSalesInfo } from "@/libs/index.js";
import { useVendorStore } from "@/store/pinia.js";
import {
  formatNumberAsCurrency,
  formatNumber,
  NAIRA_SYMBOL,
  momentObj,
} from "@/libs/utils.js";
import underScore from "underscore.string";

export default {
  name: "dashboard-default",
  components: {
    MiniStatisticsCard,
  },
  data() {
    return {
      walletBalance: 0,
      accountNumber: "Nil",
      accountName: "Nil",
      accountBankName: "Nil",
      commissionDetails: [],
      totalSalesCount: 0,
      totalSalesAmount: 0,
      totalSalesCommission: 0,
      totalSalesTitle: `Total Sales Count (${momentObj().format(
        "MMMM, YYYY"
      )})`,
      totalAmountTitle: `Total Amount Sold (${momentObj().format(
        "MMMM, YYYY"
      )})`,
      totalCommissionTitle: `Total Commission Earned (${momentObj().format(
        "MMMM, YYYY"
      )})`,

      iconBackground: "bg-gradient-success",
    };
  },
  computed: {
    ...mapStores(useVendorStore),
  },
  methods: {
    async getWalletBalance() {
      this.walletBalance = `${NAIRA_SYMBOL} ${formatNumberAsCurrency(
        await getWalletBalance(this.vendorStore.vendorId)
      )}`;

      return;
    },

    updateFundAccountInfo() {
      let infoKey = Object.keys(this.vendorStore.vendorFundInfo);
      if (infoKey.length !== 0) {
        this.accountBankName = underScore.humanize(infoKey[0]);
        this.accountName = this.vendorStore.vendorFundInfo[
          infoKey[0]
        ].accountName;
        this.accountNumber = this.vendorStore.vendorFundInfo[
          infoKey[0]
        ].accountNo;
      }
    },

    updateCommissionsInfo() {
      for (let [key, value] of Object.entries(
        this.vendorStore.vendorCommissionInfo
      )) {
        if (key === "backendCommission") {
          continue;
        }

        let details = { name: underScore.humanize(key), details: [] };

        for (let [key2, value2] of Object.entries(value)) {
          details.details.push({
            product: key2.toUpperCase(),
            percent: value2,
          });
        }

        this.commissionDetails.push(details);
      }
    },

    async fetchTotalSalesInfo() {
      let startDate = momentObj().startOf("month").format("YYYY-MM-DD");

      let endDate = momentObj().endOf("month").format("YYYY-MM-DD");

      let callResp = await getTotalSalesInfo(this.vendorStore.vendorId, {
        startDate,
        endDate,
      });

      if (callResp.length > 0) {
        this.totalSalesAmount = `${NAIRA_SYMBOL} ${formatNumberAsCurrency(
          callResp[0].totalAmount
        )}`;
        this.totalSalesCount = `${formatNumber(callResp[0].totalCount)}`;
        this.totalSalesCommission = `${NAIRA_SYMBOL} ${formatNumberAsCurrency(
          callResp[0].totalCommissionEarned
        )}`;
      }
    },
  },
  beforeMount() {
    this.getWalletBalance();
    this.updateFundAccountInfo();
    this.updateCommissionsInfo();
    this.fetchTotalSalesInfo();
  },
};
</script>

import { createRouter, createWebHistory } from "vue-router";
import { useVendorStore } from "@/store/pinia.js";

import Dashboard from "@/views/Dashboard.vue";
import Tables from "@/views/Tables.vue";
import Billing from "@/views/Billing.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
// import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";

import Vas_SignIn from "@/views/vasVendor/SignIn.vue";
import Vas_Dashboard from "@/views/vasVendor/DashboardHome.vue";
import Vas_WalletTransaction from "@/views/vasVendor/WalletTransactions.vue";
import Vas_AirtimeTransaction from "@/views/vasVendor/AirtimeTransactions.vue";
import Vas_DataTransaction from "@/views/vasVendor/DatabundleTransactions.vue";
import Vas_CableTVTransaction from "@/views/vasVendor/CableTVTransactions.vue";
import Vas_ElectricityTransaction from "@/views/vasVendor/ElectricityTransactions.vue";
import Vas_NotFoundPage from "@/views/vasVendor/NotFoundPage.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/sign-in",
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: Vas_SignIn,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Vas_Dashboard,
  },
  {
    path: "/electricityTransactions",
    name: "Electricity",
    component: Vas_ElectricityTransaction,
  },
  {
    path: "/walletTransactions",
    name: "WalletTransactions",
    component: Vas_WalletTransaction,
  },
  {
    path: "/airtimeTransactions",
    name: "Airtime",
    component: Vas_AirtimeTransaction,
  },
  {
    path: "/databundleTransactions",
    name: "Databundle",
    component: Vas_DataTransaction,
  },
  {
    path: "/cabletvTransactions",
    name: "CableTV",
    component: Vas_CableTVTransaction,
  },


  {
    path: "/Olddashboard",
    name: "OldDashboard",
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
  },

  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },

  { path: '/:pathMatch(.*)*', name: 'NotFound', component: Vas_NotFoundPage }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to) => {
  let vendorStore = useVendorStore();
  if (!vendorStore.isAuthenticated && to.name !== "Sign In" && to.name !== "NotFound") {
    console.log("Redirecting to Sign In");
    return "/sign-in";
  }
});

export default router;

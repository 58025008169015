<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <h5 class="font-weight-bolder">Query Wallet Transactions</h5>
                  <div class="d-flex flex-row">
                    <div class="me-auto p-2">
                      <p class="mb-1 pt-2 text-bold text-center">
                        Transaction Type
                      </p>
                      <vsud-input
                        @keyup.enter="processQuery"
                        id="transType"
                        type="string"
                        placeholder="Transaction Type"
                        name="transType"
                        v-model:value="searchQuery.type"
                      />
                    </div>

                    <div class="me-auto p-2">
                      <p class="mb-1 pt-2 text-bold text-center">Reference</p>
                      <vsud-input
                        @keyup.enter="processQuery"
                        id="reference"
                        type="string"
                        placeholder="Reference"
                        name="refId"
                        v-model:value="searchQuery.refId"
                      />
                    </div>

                    <div class="me-auto p-2">
                      <p class="mb-1 pt-2 text-bold text-center">Narration</p>
                      <vsud-input
                        @keyup.enter="processQuery"
                        id="narration"
                        type="string"
                        placeholder="Narration"
                        name="narration"
                        v-model:value="searchQuery.narration"
                      />
                    </div>

                    <div class="me-auto p-2">
                      <p class="mb-1 pt-2 text-bold text-center">StartDate</p>
                      <vsud-input
                        @keyup.enter="processQuery"
                        id="startDate"
                        type="date"
                        placeholder="Start Date"
                        name="startDate"
                        v-model:value="searchQuery.startDate"
                      />
                    </div>

                    <div class="me-auto p-2">
                      <p class="mb-1 pt-2 text-bold text-center">End Date</p>
                      <vsud-input
                        @keyup.enter="processQuery"
                        id="endDate"
                        type="date"
                        placeholder="End Date"
                        name="endDate"
                        v-model:value="searchQuery.endDate"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="d-flex justify-content-between">
                  <div class="p-2">
                    <vsud-button
                      @click="processQuery"
                      class="mt-4"
                      variant="gradient"
                      color="success"
                      size="lg"
                      >Enter
                    </vsud-button>
                  </div>

                  <div class="p-2">
                    <vsud-button
                      @click="processReset"
                      class="mt-4"
                      variant="gradient"
                      color="success"
                      size="lg"
                      >Reset
                    </vsud-button>
                  </div>

                  <div class="p-2">
                    <vsud-button
                      @click="processDownload"
                      class="mt-4"
                      variant="gradient"
                      color="success"
                      size="lg"
                      >Download Excel File
                    </vsud-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <table-lite
          :is-loading="table.isLoading"
          :columns="table.columns"
          :rows="table.rows"
          :total="table.totalRecordCount"
          :messages="table.messages"
          @do-search="doSearch"
          @is-finished="tableLoadingFinish"
        ></table-lite>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import TableLite from "vue3-table-lite";
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";
import { getWalletTransactionData } from "@/libs/index.js";
import { NAIRA_SYMBOL } from "@/libs/utils.js";
import { useVendorStore } from "@/store/pinia.js";

export default defineComponent({
  name: "App",
  components: { TableLite, VsudInput, VsudButton },
  setup() {
    // Table config
    let vendorStore = useVendorStore();

    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Transaction Type",
          field: "type",
          // width: "10%",
        },
        {
          label: `Amount(${NAIRA_SYMBOL})`,
          field: "amount",
          // width: "3%",
        },
        {
          label: "Reference",
          field: "refId",
          width: "15%",
        },
        {
          label: "Narration",
          field: "narration",
          width: "30%",
        },
        {
          label: "Date",
          field: "createdAt",
          // width: "15%",
        },
      ],
      rows: [],
      totalRecordCount: 0,
      messages: {
        pagingInfo: "Showing {0}-{1} of {2}",
        pageSizeChangeLabel: "Row count:",
        gotoPageLabel: "Go to page:",
        noDataAvailable: "No Data Available",
      },
      query: {},
    });

    const searchQuery = reactive({
      type: "",
      refId: "",
      narration: "",
      startDate: "",
      endDate: "",
    });

    const doSearch = (offset, limit) => {
      // console.log("For fun", offset, limit, order, sort);
      table.isLoading = true;

      getWalletTransactionData(
        vendorStore.vendorId,
        table.query,
        offset / limit + 1,
        limit
      ).then((resp) => {
        table.rows = resp.data;
        table.totalRecordCount = Number(resp.totalDataCount);
      });
    };

    const tableLoadingFinish = () => {
      table.isLoading = false;
    };

    const processQuery = () => {
      for (let [key, value] of Object.entries(searchQuery)) {
        if (value !== "") {
          table.query[key] = value;
        }
      }

      doSearch(0, 10);
    };

    const processReset = () => {
      table.query = {};

      searchQuery.type = "";
      searchQuery.refId = "";
      searchQuery.narration = "";
      searchQuery.startDate = "";
      searchQuery.endDate = "";

      doSearch(0, 10);
    };

    const processDownload = async () => {
      table.isLoading = true;
      await getWalletTransactionData(vendorStore.vendorId, {
        ...table.query,
        download: true,
      });
      setTimeout(() => {
        table.isLoading = false;
      }, 500);
    };

    doSearch(0, 10);

    return {
      table,
      searchQuery,
      doSearch,
      tableLoadingFinish,
      processQuery,
      processReset,
      processDownload,
    };
  },
});
</script>

import axios from "axios";
import { useVendorStore } from "@/store/pinia.js";
import fileDownload from 'js-file-download';
let baseURL = process.env["NODE_ENV"] === "development" ? "http://localhost:8080" : "https://business-vas.myfela.ng";



const headers = {
    Authorization: `Bearer ${process.env.VUE_APP_VAS_VENDOR_AUTH}`,
};

export async function getWalletBalance(vendorId) {
    try {
        const callResp = await axios.get(`${process.env.VUE_APP_VAS_VENDOR_URL}/merchant/getWalletBalance/${vendorId}`, { headers });
        return callResp.data.balance;
    } catch (error) {
        console.error("Error getting wallet balance");
        console.error(error);
    }
}

export async function loginCall(vendorId, password) {
    let vendorStore = useVendorStore();
    try {
        let payload = {
            merchant_id: vendorId,
            password
        };

        const callResp = await axios.post(`${process.env.VUE_APP_VAS_VENDOR_URL}/merchant/dashboardLogin`, payload, { headers });

        vendorStore.setVendorName(callResp.data.data.name);
        vendorStore.setVendorId(callResp.data.data.merchant_id);
        vendorStore.setVendorFundInfo(callResp.data.data.details.fundInfo);
        vendorStore.setVendorCommissionInfo(callResp.data.data.details.commissions);
        vendorStore.setAuthTrue();
        return { ok: true };
    } catch (error) {
        console.error("Error logging user in");
        console.error(error);
        return { ok: false };
    }
}

export async function getSalesData(vendorId, query, page = 1, limit = 10) {
    try {
        let params = {
            id: vendorId,
            ...query
        };

        let headerMore = {
            ...headers,
            "Access-Control-Allow-Origin": baseURL,
            "x-pagination-limit": limit,
            "x-pagination-page": page
        };

        let callResp;

        if (Object.keys(query).includes("download")) {
            callResp = await axios.get(`${process.env.VUE_APP_VAS_VENDOR_URL}/orders/fetchByMerchant`, { headers: headerMore, params, responseType: 'blob' });

            fileDownload(callResp.data, `${callResp.headers["content-file-name"]}`);
            return;
        } else {
            callResp = await axios.get(`${process.env.VUE_APP_VAS_VENDOR_URL}/orders/fetchByMerchant`, { headers: headerMore, params });

            if (callResp.data.length === 0) {
                return { data: [], totalDataCount: 0, totalPages: 0 };
            } else {
                return { data: callResp.data, totalDataCount: callResp.headers["x-pagination-totalcount"], totalPages: callResp.headers["x-pagination-totalpages"] };
            }
        }

    } catch (error) {
        console.error("Error getting sales data");
        console.error(error);
        return { data: [], totalDataCount: 0, totalPages: 0 };
    }
}

export async function getWalletTransactionData(vendorId, query = {}, page = 1, limit = 10,) {
    try {
        let params = {
            id: vendorId,
        };

        if (Object.keys(query).length > 0) {
            params = {
                id: vendorId,
                ...query
            };
        }

        let headerMore = {
            ...headers,
            "Access-Control-Allow-Origin": baseURL,
            "x-pagination-limit": limit,
            "x-pagination-page": page
        };

        let callResp;

        if (Object.keys(query).includes("download")) {
            callResp = await axios.get(`${process.env.VUE_APP_VAS_VENDOR_URL}/merchant/fetchWalletTransactions`, { headers: headerMore, params, responseType: 'blob' });

            fileDownload(callResp.data, `${callResp.headers["content-file-name"]}`);
            return;
        } else {
            callResp = await axios.get(`${process.env.VUE_APP_VAS_VENDOR_URL}/merchant/fetchWalletTransactions`, { headers: headerMore, params });
            if (callResp.data.length === 0) {
                return { data: [], totalDataCount: 0, totalPages: 0 };
            } else {
                return { data: callResp.data, totalDataCount: callResp.headers["x-pagination-totalcount"], totalPages: callResp.headers["x-pagination-totalpages"] };
            }
        }
    } catch (error) {
        console.error("Error getting wallet transaction data");
        console.error(error);
        return { data: [], totalDataCount: 0, totalPages: 0 };
    }
}

export async function getTotalSalesInfo(vendorId, query) {

    try {
        let params = {
            id: vendorId,
            ...query
        };

        let headerMore = {
            ...headers,
        };

        const callResp = await axios.get(`${process.env.VUE_APP_VAS_VENDOR_URL}/orders/fetchTotalSalesInfo`, { headers: headerMore, params });

        return callResp.data;
    } catch (error) {
        console.error("Error getting totalSales data");
        console.error(error);
        return [];
    }
}

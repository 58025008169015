<template>
  <main>
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <h1 class="mb-1">Oops!</h1>
        </div>
        <h2>404 - Page not found</h2>
        <p>
          The page you are looking for does not exist or might have been
          removed.
        </p>
        <a @click="goBackHome">Go To Homepage</a>
      </div>
    </div>
  </main>
</template>

<script>
import { mapMutations } from "vuex";
import { mapStores } from "pinia";
import { useVendorStore } from "@/store/pinia.js";

export default {
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    // body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    // body.classList.add("bg-gray-100");
  },
  computed: {
    ...mapStores(useVendorStore),
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    goBackHome() {
      if (this.vendorStore.isAuthenticated) {
        this.$router.push({ name: "Dashboard" });
      } else {
        this.$router.push({ name: "/" });
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,900");

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

.notfound .notfound-404 {
  height: 280px;
  position: relative;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 230px;
  margin: 0px;
  margin-top: -30px;
  font-weight: 900;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: url("../../assets/img/404BG.jpg") no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  background-clip: text;
}

.notfound h2 {
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}

.notfound p {
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0px;
}

.notfound a {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #0046d5;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #fff !important;
  cursor: pointer !important;
  font-weight: 700;
  -webkit-box-shadow: 0px 4px 15px -5px #0046d5;
  box-shadow: 0px 4px 15px -5px #0046d5;
}

.notfound a:hover {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: url("../../assets/img/404BG.jpg") no-repeat;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #fff !important;
  cursor: pointer !important;
  font-weight: 700;
  -webkit-box-shadow: 0px 4px 15px -5px #0046d5;
  box-shadow: 0px 4px 15px -5px #0046d5;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 142px;
  }

  .notfound .notfound-404 h1 {
    font-size: 112px;
  }
}
</style>
